<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()
</script>

<template>
  <UButton
    type="button"
    icon="i-heroicons-arrow-left-20-solid"
    :trailing="false"
    size="xl"
    :label="t('go_to_home')"
    color="gray"
    class="my-5"
    @click="navigateTo({ path: localePath('/') })"
  />
</template>
